import { CnFlagIcon, EnFlagIcon } from "../components/shared/icons";
import { LANG_CONFIG } from "./lang.config";

const IconComponents = {
  EnFlagIcon: EnFlagIcon,
  CnFlagIcon: CnFlagIcon,
};

export const LANG_SELECT_OPTIONS = LANG_CONFIG.map((languageItem) => {
  languageItem.icon = IconComponents[languageItem.icon];

  return languageItem;
});

export const SHOULD_BE_SMALLER_LANGUAGES = ["Русский", "Ελληνικά"];

// MT4/MT5 supported languages: https://www.mql5.com/en/articles/3024
export const MT_LANGUAGES_MAP = {
  en: "en",
  cn: "zh",
  tw: "zt",
};

// PORTAL OQTIMA supported languages: https://portal.oqtima
export const PORTAL_LANGUAGES_MAP = {
  en: "en",
  cn: "zh-Hans",
  tw: "zh-Hant",
};
